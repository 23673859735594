// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Bootstrap
@import "bootstrap";

//@import "lightslider";
@import "photoswipe";
@import "photoswipe-skin";


body { padding-top: 80px; }
h1 { margin-top: 0; }

.nav > li > a { padding: 15px 12px; }
.navbar > .container .navbar-brand { padding: 0 15px; }
@media (min-width: 480px) { .navbar > .container { padding-right: 0; } }

/*box-sizing: border-box;*/
.block { margin-bottom: 30px;  }
.block img,
.block iframe { border: 5px solid #ffffff; box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5); border-radius: 2px; }
.block h2 { font-size: 24px; font-variant: small-caps; }
.block .info { position: absolute; top: -5px; left: 10px; padding: 0 5px; background: #3e3e40; color: #ffffff; z-index: 30; border-radius: 2px; }
.block .description { position: absolute; padding: 15px; z-index: 10; text-shadow: -1px -1px 2px rgba(255,255,255,1), -1px 1px 2px rgba(255,255,255,1), 1px 1px 2px rgba(255,255,255,1), 1px -1px 2px rgba(255,255,255,1);  }
.block .right { right: 15px; text-align: right; }
.block .right h2 { padding-top: 0; }
.block .full-content { display: none; position: absolute; left: 15px; right: 15px; padding: 20px 15px 10px 15px; z-index: 20; background: rgba(62,62,62,0.9); color: #ffffff; }

#search-category-container { padding: 10px; background: #eeeeee; border-radius: 5px; text-align: justify; }
#search-category { margin-bottom: 5px; }
#category-list a { margin-bottom: 5px; }

.photo-gallery { padding: 10px 10px 0 5px; background: #eeeeee; text-align: justify; border-radius: 5px; }
.photo-gallery a:hover, .photo-gallery a:active, .photo-gallery a:focus { text-decoration: none; }
.photo-gallery a:hover img { box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 1); transition: box-shadow 0.4s ease-in-out; }
.photo-gallery figure { display: inline; }
.photo-gallery figcaption { display: none; }

.photo-gallery img { margin: 0 0 10px 5px; display:inline-block; border: 5px solid #ffffff; border-radius: 5px; }

.thumbnail-deco { border: 5px solid #dddddd; border-radius: 5px; }

#footer { padding-top: 30px; padding-bottom: 30px; }
#footer .title { padding-top: 30px; font-weight: bold; color: #a97945 }

#home h1 { margin-bottom: 30px; font-size: 16px; }
@media (min-width: 800px) { #home h1 { font-size: 20px; } }
@media (min-width: 1000px) { #home h1 { font-size: 24px; } }

#about .logo { margin: 0 0 15px 15px; }
@media (max-width: 600px) { #about .logo { width: 200px; } }
@media (max-width: 400px) { #about .logo { width: 150px; } }


/* ##3e3e40 */

.width-auto { width: 100%; }
.margin-top { margin-top: 15px; }
.margin-right { margin-right: 15px; }
.bottom { bottom: 0; }
.right { right: 0; }
.has-details { cursor: pointer; }
.box-shadow { box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 1); }
.table-bottom-border { border-bottom: 1px solid #dddddd; }

.modal {
    padding: 5px ! important;
}
.modal-dialog {
  position: relative;
  width: auto;
  max-width: 600px;
  margin: 20px auto;
}

.modal-backdrop {
    z-index: 1550;
}

.modal {
    z-index: 1560;
}

